.leaderboard {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

.leaderboard__container {
    width: 200px;
    background-color: #1e1e1e;
    border-radius: 10px;
    color: white;
    padding: 12px;
}

.leaderboard__content {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.leaderboard__header {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    color: #d3d0ff;
}

.leaderboard__subheader {
    font-size: 10px;
    padding-left: 4px;
    color: gray;
}

.leaderboard__content__score {
    display: flex;
    align-items: center;
    gap: 10px;
}

.leaderboard__content__score__info__user {
    font-weight: 200;
}

.leaderboard__content > .leaderboard__content__score:first-of-type > .leaderboard__content__score__award, 
.leaderboard__content > .leaderboard__content__score:first-of-type .leaderboard__content__score__info__speed {
    color: gold;
}

.leaderboard__content > .leaderboard__content__score:nth-of-type(2) > .leaderboard__content__score__award,
.leaderboard__content > .leaderboard__content__score:nth-of-type(2) .leaderboard__content__score__info__speed {
    color: silver;
}

.leaderboard__content > .leaderboard__content__score:nth-of-type(3) > .leaderboard__content__score__award,
.leaderboard__content > .leaderboard__content__score:nth-of-type(3) .leaderboard__content__score__info__speed {
    color: #CD7F32;
}

.leaderboard__content__score__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}