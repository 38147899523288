.button {
    cursor: pointer;
    border-radius: 4px;
    border: none;
    font-family: 'Montserrat';
}

.button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.button--primary {
    padding: 12px 20px;
    background-color: black;
    color: white;
    border: none;
}

.button--secondary {
    padding: 10px 20px;
    background-color: white;
    color: black;
    outline: 2px solid black;
}

.button--tertiary {
    padding: 10px 20px;
    background: none;
    color: black;
    border: none;
    transition: box-shadow 0.2s ease-out;
}

.button--error {
    padding: 10px 20px;
    background-color: white;
    color: #9a0f0f;
    outline: 1px solid #9a0f0f;
    font-weight: 100;
}

.button--error:hover {
    background-color: #9a0f0f;
    color: white;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.button--primary:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.button--secondary:hover {
    background-color: black;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.button--tertiary:hover {
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.75);
}

.button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.button-content__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: rotate-spinner;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate-spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}