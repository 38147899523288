.race-container {
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;
    padding: 40px;
    border-radius: 20px;
    border: 1px solid #f2f2f2;
}

.race-container__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 52px;
}

.race-container__header__text {
    font-size: 24px;
    color: #a4a4a4;
}

.race-container__header__button {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 24px;
    font-weight: 600;
    color: rgb(73, 73, 73);
}

.race-container__timer {
    font-size: 24px;
    color: #363636;
    text-decoration: underline;
}

.race-container__textarea {
    margin-top: 32px;
    width: 800px;
    font-size: 32px;
    color: #363636;
    font-family:'Source Code Pro';
}

.race-container__textarea--correct {
    /* background-color: greenyellow; */
    color: rgb(106 159 26);
}

.race-container__textarea--wrong {
    background-color: #ffcccc;
    color: #c11a1a;
}

/* @keyframes blinkCursor {
    0% {
        opacity: 0;
    }
  }

.race-container__textarea--cursor {
    width: 0;
    position: relative;
    left: -10px;
    top: -2px;
    text-align: left;
    display: inline-grid;
    font-weight: 200;

    animation: blinkCursor 1s steps(2) infinite;
} */

.race-container__inputarea {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.race-container__inputarea__icon {
    position: absolute;
    left: 10px;
    height: 24px;
    color: #aeaeae;
}

.race-container__inputarea > input {
    flex: 1;
    padding: 8px;
    height: 32px;
    font-size: 24px;
    border-radius: 8px;
    border: 0.5px solid #e3e3e3;
    color: #363636;
    font-family:'Source Code Pro';
    outline: none;
}

.race-container__inputarea__input:focus, .race-container__inputarea__input:focus-visible {
    border: 2px solid rgb(2, 21, 37);
    outline: none;
}

.race-container__inputarea__input--error {
    border: 2px solid rgb(238, 42, 42) !important;
}

.race-container__inputarea__input:disabled {
    background-color: #fdfdfd;
    cursor: not-allowed;
}

.race-container__score {
    width: 100%;
    margin-top: 32px;
}

.race-container__score__header {
    align-items: center;
    display: flex;
    gap: 4px;
}

.race-container__score__container {
    width: 200px;
    padding: 16px;
    border-radius: 8px;
    background-color: black;
    color: white;
}

.race-container__score__container > div {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.race-container__score__container > div > span:last-child {
    font-weight: 600;
}

.race-container__score__container__tag {
    background-color: rgb(137 193 53);
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 12px;
    color: white;
}

.race-container__score__container__action > a {
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
}

.race-container__score__container__action > a:hover {
    color: white;
}