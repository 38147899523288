.progress {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.progress__bar {
    width: 85%;
    border-bottom: 1px dotted black;
}

.progress__user {
    width: 100%;
    text-align: right;
}

.progress__speed {
    width: 15%;
    text-align: right;
    font-weight: 600;
}

.progress__bar__indicator {
    border-bottom: 8px solid black;
    text-align: right;
    font-size: 14px;
}
