.signin {
    background-color: #d3d0ff3b;
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    font-family: 'Montserrat';
    border-radius: 20px;
}

.signin__form {
    margin: 70px 100px;
    padding: 50px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.signin__form__header__title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
}

.signin__form__field {
    border-bottom: 1px solid #ececec;
    display: flex;
}

.signin__form__field:not(:last-of-type) {
    margin-bottom: 16px;
}

.signin__form__field--active {
    border-bottom: 2px solid rgb(48, 48, 48);
    margin-bottom: -1px;
}

.signin__form__field__input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 4px;
    font-size: 20px;
}

.signin__form__field__input::-webkit-input-placeholder {
    color: rgb(36, 36, 36);
    font-size: 18px;
    font-family: 'Montserrat';
}

.signin__form__field__reset-password {
    margin-left: 4px;
    font-size: 12px;
    color: #888;
    cursor: pointer;
}

.signin__form__field__reset-password:hover {
    text-decoration: underline;
    color: black;
}

.signin__form__button-signup {
    width: 100%;
    height: 48px;
    border: none;
    background-color: black;
    font-size: 18px;
    border-radius: 7px;
    color: white;
    cursor: pointer;
    transition: box-shadow 0.5s;
}

.signin__form__button-login {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    height: 44px;
    border: none;
    font-size: 20px;
    background: none;
    border: 2px solid black;
    border-radius: 8px;
    cursor: pointer;
}

.signin__form__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.signin__form__actions > * {
    width: 100%;
}