.user-score {
    margin: 12px;
}

.user-score__score__header {
    font-weight: 600;
    margin-bottom: 8px;
}

.user-score__score__container {
    font-size: 12px;
    border-top: 1px solid #ffffff4a;
}

.user-score__score__container > div {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-score__score__container > div > span:last-child {
    font-weight: 600;
    text-align: start;
    width: 60px;
}
