.score-history {
    width: 100%;
    margin-top: 32px;
}

.score-history__tabs {
    display: flex;
    gap: 24px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    cursor: pointer;
    padding-bottom: 2px;
    align-items: center;
}

.score-history__tabs__icon {
    cursor: default;
}

.score-history__header--active {
    border-bottom: 2px solid black;
}

.score-history__header:hover {
    border-bottom: 2px solid black;
}

.score-history__table {
    width: 100%;
    padding: 10px 0;
    border-radius: 8px;
    border-collapse: collapse;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.score-history__table tr {
    border-bottom: 0.5px solid #efefef;
}

thead > tr > th {
    padding: 12px 16px;
}

th, td {
    padding: 8px 16px;
}

th:first-of-type {
    border-top-left-radius: 8px;
}

th:last-of-type {
    border-top-right-radius: 8px;
}

tbody > tr:hover {
    background-color: #f3f3f3;
}

.score-history__table__head {
    text-align: left;
    background-color: black;
    color: white;
}

.score-history__empty {
    color: gray;
}