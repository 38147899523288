.navbar {
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar__right__logged-in, .navbar__right__logged-out {
    display: flex;
    direction: row;
    align-items: center;
}

.navbar__left {
    background: #d3d0ff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
    border-radius: 12px;
}

.navbar__right {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-right: 12px;
}

.navbar__right__actions {
    display: flex;
    gap: 24px;
    align-items: center;
}

.navbar__right__actions__github > a, .navbar__right__actions__twitter > a {
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    background-color: #363636;
    color: #f7f7f7;
    display: flex;
    align-items: center;
}

.navbar__right__actions__github > a:hover, navbar__right__actions__twitter > a:hover {
    color: white;
}

.navbar__right__logged-in__user {
    border-radius: 4px;
    padding: 4px 16px;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    outline: 1px solid;
}

.navbar__right__logged-in__user:hover {
    color: rgb(8, 8, 8);
    outline: 2px solid;
}

.navbar__right__logged-in__user:hover > div > span {
    text-decoration: underline;
}

.navbar__right__logged-in__user__score {
    font-size: 12px;
    color: #1991f5;
}

.navbar__right__logged-in {
    display: flex;
    gap: 24px;
}

.navbar__left__logo {
    transition: transform 0.5s;
}

.navbar__left:hover .navbar__left__logo {
    transform: scale(1.1);
}